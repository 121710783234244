import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Box, Col, Flex, Text } from '../Ui';
import { ReactComponent as PointIcon } from '../../assets/images/pointIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import ReactShadowScroll from 'react-shadow-scroll';
import moment from 'moment';
import { numberWithDots } from '../../utils/utils';
import { respondTo } from '../../theme/mixin';
import { getUserInfo } from '../../store/actions';
import customAxios from '../../config/axios-refresh-token';
const CustomBox = styled(Box)`
  & > * + * {
    border-top: 1px solid ${({ theme }) => theme.colore_bordo_box};
  }
`;
const CustomCol = styled(Col)`
  svg {
    color: ${({ theme }) => theme.primary};
  }
`;
const CustomFlexHeader = styled(Flex)`
  border-bottom: 1px solid ${({ theme }) => theme.colore_bordo_box};
  display: none;
  ${respondTo.sm`
  display: flex;
     `};
`;
const CustomColPoint = styled(Col)`
  width: 25%;
  ${respondTo.sm`
  width: 20%;
     `};
`;
const CustomText = styled(Text)`
  display: block;
  ${respondTo.sm`
  display: none;
     `};
`;
const Details = styled.details`
  padding: 15px;

  summary {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    position: relative;
    font-weight: bold;

    margin-bottom: 5px;
  }
`;

const Punti = ({ text, num }) => {
  const { actionsPerCategory } = useSelector(state => state.user.user || {});

  return text === 'Punti maturati' ? (
    <Details val={num}>
      <summary>
        <Text size={20} text_box>
          Punti maturati
        </Text>
        <Text size={20} align='right' bold text_box>
          {numberWithDots(num)}
        </Text>
      </summary>

      {actionsPerCategory &&
        Object.keys(actionsPerCategory)?.map(item => (
          <Flex justify='space-between' align='center' wrap='nowrap' className='mb-10'>
            <Text size={20} text_box>
              {item}
            </Text>
            <Text size={20} text_box>
              {actionsPerCategory[item].total}
            </Text>
          </Flex>
        ))}
    </Details>
  ) : (
    <Flex justify='space-between' align='center' wrap='nowrap'>
      <Col width={50}>
        <Text size={20} text_box>
          {text}
        </Text>
      </Col>
      <Col width={50}>
        <Text size={20} align='right' bold text_box>
          {numberWithDots(num)}
        </Text>
      </Col>
    </Flex>
  );
};
const Coin = ({ text, num, category, date }) => (
  <>
    <Flex align='center' wrap='nowrap'>
      <Col width={80}>
        <Flex gap={30}>
          <Col width={50} padding={5}>
            <Flex wrap='nowrap' gap={5}>
              <CustomText bold capit text_box>
                Data:
              </CustomText>
              <Text size={18} text_box style={{ whiteSpace: 'nowrap' }}>
                {moment(date).format('DD/MM/YYYY HH:MM')}
              </Text>
            </Flex>
          </Col>
          <Col width={50} padding={5}>
            <Text size={18} text_box>
              {category}
            </Text>
          </Col>
        </Flex>
      </Col>
      <CustomColPoint width={20}>
        <Text size={18} upper type='text' text_box align='right'>
          {numberWithDots(num)}
        </Text>
      </CustomColPoint>
    </Flex>
  </>
);

const Points = () => {
  const {
    userinfo: { score, score_spent, score_gained, score_pending } = {},
    actions,
    base_threshold_enabled,
    base_threshold_description,
    base_threshold_percent,
    targets,
    actionsPerCategory,
  } = useSelector(state => state.user.user || {});
  const appName = useSelector(state => state.app.config.code);
  const dispatch = useDispatch();

  useEffect(() => {
    customAxios.post(`/auth/updatepoints`).then(() => {
      dispatch(getUserInfo());
    });
  }, []);
  return (
    <>
      {base_threshold_enabled && (
        <Box>
          <Text align='center' as='h3' bold type='mediumTitle' className='mb-10' text_box>
            Soglia base {base_threshold_description}
          </Text>
          <Text align='center' as='h3' bold type='mediumTitle' className='mb-10' text_box>
            Hai raggiunto il
          </Text>
          <Text align='center' as='h3' bold type='title' primary className='mb-10' text_box>
            {base_threshold_percent}
          </Text>
          <Text align='center' as='h3' bold type='mediumTitle' text_box>
            della soglia base
          </Text>
        </Box>
      )}
      {targets?.length > 0 && (
        <Box style={{ marginTop: '15px' }}>
          <ReactShadowScroll
            style={{ width: '100%', maxHeight: '180px' }}
            isShadow={false}
            scrollColor={'#cccccc'}
            scrollColorHover='gray'
            styleSubcontainer={{ overflowX: 'hidden' }}
          >
            {targets?.map(item => (
              <Text primary capit className='mb-10' size={20} style={{ paddingLeft: '15px' }}>
                Obiettivo {item.month}: {item.target}
              </Text>
            ))}
          </ReactShadowScroll>
        </Box>
      )}
      <CustomBox margin='15px 0 0 0'>
        <Punti text='Punti maturati' num={score_gained} actionsPerCategory={actionsPerCategory} />
        <Punti text='Punti spesi' num={score_spent} />
        <Punti text='Punti disponibili' num={score} />

        {appName && appName === 'WURTH' ? (
          <Punti text='Punti da confermare' num={score_pending} />
        ) : null}
      </CustomBox>

      {actions?.length > 0 && (
        <Box style={{ height: '100%' }} margin='15px 0 0 0'>
          <CustomFlexHeader align='center' wrap='nowrap'>
            <Col width={80} padding={0}>
              <Flex>
                <Col width={50}>
                  <Text size={18} bold primary align='left' style={{ paddingLeft: '8px' }}>
                    Data
                  </Text>
                </Col>
                <Col width={50} style={{ paddingLeft: '0' }}>
                  <Text size={18} bold primary align='left'>
                    Categorie
                  </Text>
                </Col>
              </Flex>
            </Col>
            <Col width={20} padding={0}>
              <Text size={18} bold primary align='right' style={{ paddingRight: '20px' }}>
                Punti
              </Text>
            </Col>
          </CustomFlexHeader>
          <ReactShadowScroll
            style={{ width: '100%', maxHeight: '370px' }}
            isShadow={false}
            scrollColor={'#cccccc'}
            scrollColorHover='gray'
            styleSubcontainer={{ overflowX: 'hidden' }}
          >
            {actions?.map(({ id, details, points, category, created_at }) => (
              <Coin key={id} num={points} text={details} category={details} date={created_at} />
            ))}
          </ReactShadowScroll>
        </Box>
      )}
    </>
  );
};
export default Points;
