import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { respondTo } from '../../../theme/mixin';
import Text from '../Text';

const LogoContainer = styled.div`
  //max-width: ${props => (props.theme.logo_size ? props.theme.logo_size + 'px' : 'auto')};
  margin-right: 30px;
  display: flex;
  gap: 10px;
  align-items: center;
  margin-left: ${({ marginLeft }) => (marginLeft ? marginLeft : 'auto')};
  ${respondTo.sm`
        gap:50px
  `};
  a {
    outline: none;
  }
  ${respondTo.sm`
       margin-right: 60px;
  `};
  .seperator {
    width: 2px;
    background-color: #f8d500;
    height: 60px;
    ${respondTo.sm`
        height: 96px;
  `};
  }

  .word {
    /* font-family: 'aleo'; */
    color: ${props => props.theme.bg_bottoni_secondari};
    font-size: 20px;
    ${respondTo.sm`
        font-size: 41px;
  `};
  }
`;

const Logo = props => {
  const { nolink, logo } = props;

  let logoContent = null;

  if (nolink) {
    logoContent = <img src={logo} alt='' />;
  } else {
    logoContent = (
      <Link to='/'>
        <img src={logo} alt='' />
      </Link>
    );
  }

  return (
    <LogoContainer {...props}>
      {logoContent}
      <div className='seperator'></div>
      <Text upper bold className='word'>
        aperiwin
      </Text>
    </LogoContainer>
  );
};

Logo.propTypes = {
  nolink: PropTypes.bool,
  logo: PropTypes.string,
};

export default Logo;
