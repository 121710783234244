import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { NavLink } from 'react-router-dom';
import { respondTo } from '../../../../theme/mixin';

const LinkElem = styled(NavLink)`
  ${respondTo.sm`
   display: flex;
    text-decoration: none;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
    z-index:1; 
  `};

  span {
    display: block;
    transition: 0.4s;
    color: ${({ theme }) => theme.colore_testo_menu_esterno};
    margin-top: 20px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;

    ${respondTo.sm`
      margin-top: 10px;
      font-weight: normal;
      font-size: 12px;
  `};
  }

  svg,
  img {
    color: ${({ theme }) => theme.colore_testo_menu_esterno};
    display: none;
    ${respondTo.sm`
      display:block;   
  `};
  }
  img {
    width: 55px;
    max-width: initial;
  }
  .numWish {
    color: ${({ theme }) => theme.colore_testo_menu_esterno};
  }
  &.active,
  &:hover {
    span {
      color: ${({ theme }) => theme.colore_testo_hover_menu_esterno};
    }
    svg {
      color: ${({ theme }) => theme.colore_testo_hover_menu_esterno};
    }
    .icon {
      background-color: ${({ theme }) => theme.colore_testo_hover_menu_esterno};
    }
    .numWish {
      color: ${({ theme }) => theme.colore_testo_hover_menu_esterno};
    }
  }
`;

const NavigationItem = props => {
  const { link, children } = props;

  return (
    <li style={{ position: 'relative' }}>
      <LinkElem exact to={link} {...props}>
        {children}
      </LinkElem>
    </li>
  );
};

NavigationItem.propTypes = {
  link: PropTypes.string,
  children: PropTypes.any,
};

export default NavigationItem;
