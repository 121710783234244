import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import NavigationItem from './NavigationItem/NavigationItem';
import { MaskImg, Text } from '../../Ui';
import { respondTo } from '../../../theme/mixin';
import { useTranslation } from 'react-i18next';
import Logout from '../../Logout/Logout';
import { BsCart3 } from 'react-icons/bs';
import { ReactComponent as CartIcon } from '../../../assets/images/cart.svg';
import { ReactComponent as WishlistIcon } from '../../../assets/images/wishlist.svg';
import { ReactComponent as Catalog } from '../../../assets/images/catalog.svg';
import { ReactComponent as Contact } from '../../../assets/images/contact.svg';
import { ReactComponent as Notif } from '../../../assets/images/notif.svg';
import { useSelector } from 'react-redux';
const NavUl = styled.ul`
  list-style: none;
  background-color: #fff;
  padding: 15px;
  & > li:first-child > a > span {
    margin-top: 0;
  }
  ${respondTo.sm`
    display: flex;
    align-items: center;
    align-items:baseline;
    height: 100%;
  background-color: transparent;

    box-shadow: none;
    padding: 0;
	`}
  .only-mobile {
    display: block;
    //border-top: 1px solid #dce4e8;
    //padding: 15px 0;

    ${respondTo.sm`
      display: none;
    `}
  }
`;
const NumCart = styled.p`
  display: none;
  ${respondTo.sm`
  display:block;
  position: absolute;
  top: 8px;
  right: 6px;
  width: 25px;
  height: 25px;
  color: white !important;
  background-color: red;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
    `}
`;
const NumNotif = styled(NumCart)`
  ${respondTo.sm`
  top: 3px;
  right: 22px;
    `}
`;
const Numwish = styled.p`
  display: none;
  color: ${({ theme }) => theme.colore_testo_menu_esterno};
  &:hover {
    color: ${({ theme }) => theme.colore_testo_hover_menu_esterno};
  }
  ${respondTo.sm`
  display: block;
  position: absolute;
  top: 26px;
  font-weight: bold;
  font-size: 18px;
    `}
`;
const MaskImgCustom = styled(MaskImg)`
  display: none;
  background-color: ${({ theme }) => theme.colore_testo_menu_esterno};
  z-index: -1;
  ${respondTo.sm`
  display: block;

    `}
`;
const NavigationItems = props => {
  const { isLogged, multipromo, i18l, idGame, isOpen, logout, setIsMenuOpen, setHideBurger } =
    props;
  const { wishlist, cart } = useSelector(state => state.user.user);
  /* const { catalogs } = useSelector(state => state.products.products); */
  const num = useSelector(
    state => state.news?.news?.filter(item => !item.read && item.visible_in_list && item)?.length,
  );
  const option = useSelector(state => JSON.parse(state.app.config.settings.option) || {});

  const [t] = useTranslation();
  /*"wishlist":{"visibility":"show","label":"Preferiti"},"cart":{"visibility":"show","label":"Carrello"},"news":{"visibility":"show","label":"Notifiche"}}} */

  const ulRef = node => {
    if (node) {
      node.childNodes.length === 0 ? setHideBurger(true) : setHideBurger(false);
    }
  };
  return (
    <NavUl isOpen={isOpen} ref={ulRef}>
      {/* multipromo ? (
        <NavigationItem link='/' active='true'>
          <Text>{i18l.nav.allthepromo ? i18l.nav.allthepromo : t('app.allthepromo')}</Text>
        </NavigationItem>
      ) : null */}
      {isLogged && (
        <>
          <NavigationItem
            link={`/`}
            active='true'
            onClick={() => setIsMenuOpen(false)}
            className='only-mobile'
          >
            <Text size={12} upper>
              Home
            </Text>
          </NavigationItem>
          {option?.menuSettings?.catalog?.visibility === 'show' && (
            <NavigationItem link={`/catalog`} active='true' onClick={() => setIsMenuOpen(false)}>
              {option?.menuSettings?.catalog?.icon_visibility === 'show' ||
              !option?.menuSettings?.catalog?.icon_visibility ? (
                option?.menuSettings?.catalog?.icon_url ? (
                  <MaskImgCustom src={option?.menuSettings?.catalog?.icon_url} className='icon' />
                ) : (
                  <Catalog />
                )
              ) : null}
              <Text size={12} upper>
                {option?.menuSettings?.catalog?.label}
              </Text>
            </NavigationItem>
          )}
          {option?.menuSettings?.contacts?.visibility === 'show' && (
            <NavigationItem link='/contact' active='true' onClick={() => setIsMenuOpen(false)}>
              {option?.menuSettings?.contacts?.icon_visibility === 'show' ||
              !option?.menuSettings?.contacts?.icon_visibility ? (
                option?.menuSettings?.contacts?.icon_url ? (
                  <MaskImgCustom src={option?.menuSettings?.contacts?.icon_url} className='icon' />
                ) : (
                  <Contact />
                )
              ) : null}
              <Text size={12} upper>
                {option?.menuSettings?.contacts?.label}
              </Text>
            </NavigationItem>
          )}
          {option?.menuSettings?.wishlist?.visibility === 'show' && (
            <NavigationItem
              link='/wishlist'
              active='true'
              hoverIcon
              onClick={() => setIsMenuOpen(false)}
            >
              {option?.menuSettings?.wishlist?.icon_url ||
              option?.menuSettings?.wishlist?.icon_visibility === 'hide' ? (
                wishlist.length > 0 && <NumCart>{wishlist.length}</NumCart>
              ) : (
                <Numwish className='numWish'>{wishlist.length}</Numwish>
              )}
              {option?.menuSettings?.wishlist?.icon_visibility === 'show' ||
              !option?.menuSettings?.wishlist?.icon_visibility ? (
                option?.menuSettings?.wishlist?.icon_url ? (
                  <MaskImgCustom src={option?.menuSettings?.wishlist?.icon_url} className='icon' />
                ) : (
                  <WishlistIcon />
                )
              ) : null}
              <Text size={12} upper capit>
                {option?.menuSettings?.wishlist?.label}
              </Text>
            </NavigationItem>
          )}
          {option?.menuSettings?.cart?.visibility === 'show' && (
            <NavigationItem link='/cart' active='true' onClick={() => setIsMenuOpen(false)}>
              {cart.length > 0 && <NumCart>{cart.length}</NumCart>}
              {option?.menuSettings?.cart?.icon_visibility === 'show' ||
              !option?.menuSettings?.cart?.icon_visibility ? (
                option?.menuSettings?.cart?.icon_url ? (
                  <MaskImgCustom src={option?.menuSettings?.cart?.icon_url} className='icon' />
                ) : (
                  <CartIcon />
                )
              ) : null}
              <Text size={12} upper>
                {option?.menuSettings?.cart?.label}
              </Text>
            </NavigationItem>
          )}
          {option?.menuSettings?.news?.visibility === 'show' && (
            <NavigationItem
              link='/notifications'
              active='true'
              onClick={() => setIsMenuOpen(false)}
            >
              {option?.menuSettings?.news?.icon_url ||
              option?.menuSettings?.news?.icon_visibility === 'hide'
                ? num > 0 && <NumCart>{num}</NumCart>
                : num > 0 && <NumNotif>{num}</NumNotif>}
              {option?.menuSettings?.news?.icon_visibility === 'show' ||
              !option?.menuSettings?.news?.icon_visibility ? (
                option?.menuSettings?.news?.icon_url ? (
                  <MaskImgCustom src={option?.menuSettings?.news?.icon_url} className='icon' />
                ) : (
                  <Notif />
                )
              ) : null}
              <Text size={12} upper>
                {option?.menuSettings?.news?.label}
              </Text>
            </NavigationItem>
          )}
        </>
      )}
      {i18l?.nav.menu_static_links
        ? i18l.nav.menu_static_links.map((item, i) => {
            return (
              <NavigationItem link={item.url} active='true' key={i}>
                <Text>{item.label}</Text>
              </NavigationItem>
            );
          })
        : null}
    </NavUl>
  );
};

NavigationItems.propTypes = {
  isLogged: PropTypes.string,
};
export default NavigationItems;
