import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { MeetingRoom } from '@styled-icons/material-twotone';
import { respondTo } from '../../theme/mixin';

const LogoutContainer = styled.button`
  cursor: pointer;
  font-size: 18px;
  border: none;
  outline: none;
  font-weight: bold;
  //background-color: ${({ theme }) => theme.bg_bottoni || theme.primary};
  //color: ${({ theme }) => theme.colore_testo_bottoni || theme.default_text_color};
  padding: 5px 10px;
`;

const Logout = props => {
  const { label } = props;

  return <LogoutContainer {...props}>{label}</LogoutContainer>;
};

Logout.propTypes = {
  children: PropTypes.any,
  label: PropTypes.string,
};

export default Logout;
